import classNames from "classnames";
import classNamesBind from "classnames/bind";
import { MouseEvent } from "react";
import {
  CosmosButtonProps,
  CosmosButton,
  CosmosIconChevronRight,
  CosmosIconChevronLeft,
} from "@cosmos/web/react";
import styles from "./slider-button.module.css";

const cx = classNamesBind.bind(styles);

export const SliderButton = ({
  kind,
  className,
  onClick,
  accessibilityLabel,
  disabled,
  buttonKind = "primary",
  size = "large",
  ...otherProps
}: {
  kind: "previous" | "next";
  className?: string;
  onClick?: (event: MouseEvent) => void;
  accessibilityLabel?: string;
  disabled?: boolean;
  buttonKind?: CosmosButtonProps["kind"];
  size?: CosmosButtonProps["size"];
}) => {
  const IconComponent =
    kind === "previous" ? CosmosIconChevronLeft : CosmosIconChevronRight;
  // todo: localise these next/prev defaults:
  const accessibilityLabelDefault = kind === "previous" ? "Previous" : "Next";
  return (
    <CosmosButton
      className={classNames(className, cx("container"))}
      shape="circle"
      size={size}
      icon-placement={kind === "previous" ? "before" : "after"}
      onClick={onClick}
      disabled={disabled}
      kind={buttonKind}
      type="button" // prevent accidental form submits
      {...otherProps}
    >
      <IconComponent className={cx("icon")} slot="icon" />
      {/* Below text is visually hidden, used for screen readers */}
      <span className={cx("sr-only")}>
        {accessibilityLabel || accessibilityLabelDefault}
      </span>
    </CosmosButton>
  );
};
